import React,{ useState } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion"
import "@reach/accordion/styles.css"

const Downloads = ({ download,downloadsMainHeading,downloadsSubHeading }) => {
  const [index, setIndex] = useState(0);
  function formatURL(url) {
    var newURL = url.replace(/^.*\/\/[^\/]+/, '');
    return newURL;
  }
  return (
    <section className="content very-light-blue-background bg-enforce">
      <div className="eighty-spacer"></div>
      <div className="content-block">
        <div className="centered-title-holder">
        {downloadsMainHeading ?
          <h1 className="staggerFadeInFirst first-load">
            {downloadsMainHeading}
          </h1>
          : null}
          {downloadsSubHeading ?
          <span className="under-centered-title">
            {downloadsSubHeading}
          </span>
          : null}
        </div>
        {downloadsMainHeading ?
        <div className="eighty-spacer"></div>
        : null}
        <Accordion collapsible>
        {download.length
          ? download.map((cat,index) => (
              <span key={index}>
                  <AccordionItem className="info-accordion-holder staggerFadeInFirst first-load">
                    <div className="info-sub-heading-holder">
                      <AccordionButton className="info-sub-heading">
                        {cat.name}
                        <div className="white-mango-status-icon info-plus"></div>
                      </AccordionButton>
                    </div>
                    <AccordionPanel index={index}  className="info-text-holder">
                      {cat.downloads.nodes.map((download,index) => (
                        <span key={index}>
                          {download.downloadDetails.downloadFile ? (
                            <a
                              href={formatURL(
                                download.downloadDetails.downloadFile
                                  .mediaItemUrl)
                              }
                              target="_blank" rel="noopener noreferrer"
                            >
                              <div className="download-holder">
                                <div
                                  className="download-name"
                                  dangerouslySetInnerHTML={{
                                    __html: download.title,
                                  }}
                                />
                                <div className="download-link-button hide-on-responsive">
                                  <span className="download-icon"></span>
                                  {download.downloadDetails
                                    .downloadButtonText ? (
                                    <>
                                      {
                                        download.downloadDetails
                                          .downloadButtonText
                                      }
                                    </>
                                  ) : (
                                    "Download PDF"
                                  )}
                                </div>
                                <span className="download-icon show-on-responsive"></span>
                              </div>
                            </a>
                          ) : null}
                        </span>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>
              </span>
            ))
          : null}
          </Accordion>
      </div>
      <div className="one-hundred-spacer"></div>
    </section>
  )
}

export default Downloads
